import React from 'react';
import {useState, useEffect, useRef} from 'react';
import {useNavigate, useActionData, Form, Link} from 'react-router-dom';
import axios from 'axios';
import { FaSearch } from "react-icons/fa";
import validations from '../components/validations/validations'
import { AiFillEye } from "react-icons/ai";
import { AiFillEyeInvisible } from "react-icons/ai";
import { getRecaptcha } from '../auth/reCaptcha/reCaptcha';
import { FaSquarePlus } from "react-icons/fa6";
function Register() {
  const SITE_KEY = process.env.REACT_APP_DOCON_RECAPTCHA_SITE_KEY;
  const [company_id,setCompanyId] = useState();
  const [password,setPassword] = useState();
  const [matchPassword,setMatchPassword] = useState();
  const [verifyOTP,setVerifyOTP] = useState();
  const [verifyEmail,setVerifyEmail] = useState();
  const [email,setEmail] = useState();
  const [kinhto,setKinhto] = useState();
  const [onoma,setOnoma] = useState();
  const [epwnhmo,setEpwnhmo] = useState();
  const [afmError,setAfmError] = useState(false);
  const [passwordError,setPasswordError] = useState(false);
  const [epwnhmia,setEpwnhmia] = useState();
  const [visiblePassword,setVisiblePassword] = useState(false);
  const [visibleMatch,setVisibleMatch] = useState(false);
  const [csrfToken,setCsrfToken] = useState();
  const [edit,setEdit] = useState(false);
  const [coupon,setCoupon] = useState(false);
  const navigate = useNavigate(); 
  const input = useRef();
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const token = getRecaptcha().then().catch();

  const submitData = async (token) =>{
    try{
      await axios.post(BASE_URL+ '/reCaptcha',{token},{withCredentials:true})
      .then(res=>{
        console.log(res.data.message);
      })
      .then(
        await axios.post(BASE_URL+ '/register',{onoma,epwnhmo,epwnhmia,email,kinhto,password,matchPassword,company_id}, {withCredentials: true})
        .then(res=>{
            if(res.status === 200){
                console.log(res.status);
                navigate('/');
            }   
            
        })
        .catch(err=>{
            console.log(err);
        })
      )
      .catch(err=>{
        console.log(err);
      })
     
     
  } catch(error) {  
      
      console.log(error);
  }
}



  const onRegister = async(e) =>{
    e.preventDefault();
    searchAFM();
    window.grecaptcha.ready(() => {
      window.grecaptcha.execute(SITE_KEY, { action: 'submit' }).then(token => {
        submitData(token);
      });
    });
   
  }

  const searchAFM = async () =>{
    if(validations.isValidAFM(input.current.value)===false){
      setAfmError(true);
    }
  }

  const setMyCompanyId = (e)=>{
    setCompanyId(e.target.value);
    setAfmError(false);
  }

  const validateEmail = async () =>{

  }

  const validatePhone = async () =>{
    

  }
  const validateCoupon = async () =>{
  }

  const checkPasswords = (e) =>{
    if(password && password!==e.target.value){
      setPasswordError(true);
    }
  }


const setmyPassword = (e) =>{
  setPassword(e.target.value);
  setPasswordError(false);
}



  return (
    <div className="bg-[#1a97af] w-full h-screen overflow-y-auto">
    <div className=" bg-[#1a97af] flex flex-col  items-center justify-items-center justify-center my-auto px-6 py-8 md:py-[25%] lg:py-8 mx-auto " >
    <Form className=" lg:w-[700px] lg:h-[1050px] md:w-[600px] md:h-[1050px] w-auto h-auto flex justify-left justify-items-start items-start bg-white rounded-lg dark:bg-gray-800 dark:border-gray-700 shadow dark:border" onSubmit={onRegister}>
    <div className="flex flex-col w-full mx-6">
      <h1 className="flex mt-10 text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white justify-items-center justify-center">
        Δημιουργία λογαριασμού</h1>
          <h2 className="text-[15px] font-normal text-gray-900 dark:text-white mt-5 justify-items-center items-center justify-center flex">Παρακαλώ εισάγετε τα παρακάτω στοιχεία εγγραφής.</h2>
          <p className="text-[15px] font-normal text-gray-900 dark:text-white mt-1 justify-center justify-items-center items-center flex">Χρησιμοποιείστε το ΑΦΜ σας για να συμπληρωθούν αυτόματα τα στοιχεία σας.</p>
          <div className="flex flex-col mt-5">
            <div className="flex w-[60%]">
            <label className="flex mb-2 text-sm font-medium text-gray-900 dark:text-white">ΑΦΜ Εταιρείας</label>
            </div>
            <div className="flex flex-row justify-between">
            <input type="number" ref={input}  onChange={setMyCompanyId} onWheel={ event => event.currentTarget.blur() } className="bg-gray-50 border mr-4 flex border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none" required />
           
            <button className=" w-40 h-10 px-3 py-2 flex rounded-md bg-blue-700 text-sm hover:bg-blue-400" type="button" onClick={searchAFM}>
              <FaSearch className="flex size-3 my-auto text-white mr-3" />
              <span className="text-sm my-auto md:my-1 text-white font-semibold sm:w-0 sm:h-0 md:w-2">Αναζήτηση</span>
            </button>
            </div>
            {afmError && (<p className={`${afmError ? "flex text-red-500 text-sm mt-2": "max-h-0 invisible"}`}>Το ΑΦΜ που πληκτρολογήσατε δεν είναι έγκυρο</p>)}
          </div>

        
          <div className="flex flex-col">
            <div className="flex mt-4 flex-col">
              <label className="flex mb-2 text-sm font-medium text-gray-900 dark:text-white">Επωνυμία εταιρείας</label>
              <input type="text" defaultValue={epwnhmia}  onChange={e=>setEpwnhmia(e.target.value)} className="bg-gray-200 border mr-4 flex border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required/>
            </div>

              {/* Ονοματεπώνυμο */}
          <div className="flex flex-row w-auto mt-5">

            <div className="flex w-full flex-col mr-4">
              <label className="flex mb-2 text-sm font-medium text-gray-900 dark:text-white">Όνομα</label>
              <input type="text" defaultValue={onoma} onChange={e=>setOnoma(e.target.value)} placeholder="Όνομα" className="bg-gray-50 border mr-4 flex border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required/>
            </div>

            <div className="flex w-full flex-col">
              <label  className="flex mb-2 text-sm font-medium text-gray-900 dark:text-white">Επώνυμο</label>
              <input type="text" defaultValue={epwnhmo} onChange={e=>setEpwnhmo(e.target.value)} placeholder="Επώνυμο" className="bg-gray-50 border mr-4 flex border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required/>
          </div>
          </div>
          
           {/* Κωδικός, επανάληψη κωδικού */}
          <div className="flex flex-row w-auto mt-5">

            <div className="flex w-full flex-col mr-4">
              <label className="flex mb-2 text-sm font-medium text-gray-900 dark:text-white">Κωδικός</label>
              <div className="flex justify-between items-center focus-within:border-2 mx-1 focus-within:outline-none border focus-within:border-gray-600 focus-within:shadow-md border-gray-300 dark:bg-gray-700  outline-none rounded-lg h-10">
              <input type={visiblePassword? "text" : "password"}  onChange={setmyPassword} placeholder="••••••••" className="w-full text-gray-900 dark:placeholder-gray-400 dark:text-white dark:bg-gray-700 border border-none pl-5 px-3 focus:border-none focus:outline-none" required />
                <div className="p-1 hover:cursor-pointer" onClick={()=>setVisiblePassword(!visiblePassword)}>
                  {visiblePassword ? <AiFillEye /> : <AiFillEyeInvisible />}
                </div>
              </div>
            </div>

            <div className="flex w-full flex-col">
              <label  className="flex mb-2 text-sm font-medium text-gray-900 dark:text-white">Επανάληψη κωδικού</label>
              <div className="flex justify-between items-center focus-within:border-2 mx-1 focus-within:outline-none border focus-within:border-gray-600 focus-within:shadow-md border-gray-300 outline-none rounded-lg h-10 dark:bg-gray-700">
              <input type={visibleMatch ? "text" : "password"} onBlur={checkPasswords} onFocus={()=>setPasswordError(false)} onChange={e=>setMatchPassword(e.target.value)} placeholder="••••••••" className="w-full text-gray-900 border border-none dark:text-white dark:bg-gray-700 pl-5 px-3 focus:border-none focus:outline-none" required />
                <div className="p-1 hover:cursor-pointer" onClick={()=>setVisibleMatch(!visibleMatch)}>
                  {visiblePassword ? <AiFillEye /> : <AiFillEyeInvisible />}
                </div>
              </div>
            </div>
          </div>


          
          <div className="flex w-full h-5">
          {passwordError && (<p className={` ${passwordError ? "text-sm text-red-400 ml-2" : "max-h-0 invisible w-0"}`}>Ο κωδικός δεν είναι ίδιος...</p>)}
          </div>


           {/* Email */}
            <div className="flex mt-4 flex-col w-full">
            <label className="flex mb-2 text-sm font-medium text-gray-900 dark:text-white">Email</label>
              <div className="flex flex-row w-full">
              <div className="flex w-full">
              <input type="email" disabled={edit} defaultValue={email} onChange={e=>setEmail(e.target.value)} placeholder="Το email της επιχείρησης" className="bg-gray-50 border mr-4 flex border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required/>
              </div>
              <div className="flex">
                <button className=" w-35 h-15 px-3 py-2 flex rounded-md bg-blue-700 text-sm hover:bg-blue-400" type="button" onClick={validateEmail}>
                  <span className="text-sm my-auto text-white font-semibold">Επαλήθευση</span>
                </button>
              </div>
            </div>
          </div>

           {/* Κινητό */}
            <div className="flex mt-4 flex-col w-full">
            <label className="flex mb-2 text-sm font-medium text-gray-900 dark:text-white">Κινητό τηλέφωνο</label>
              <div className="flex flex-row w-full">
              <div className="flex w-full">
              <input type="number" disabled={edit} defaultValue={kinhto} onChange={e=>setKinhto(e.target.value)} placeholder="Το κινητό τηλέφωνο της επιχείρησης" className="bg-gray-50 border mr-4 flex border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none" required/>
              </div>
              <div className="flex">
              <button className=" w-35 h-15 px-3 py-2 flex rounded-md bg-blue-700 text-sm hover:bg-blue-400" type="button" onClick={validatePhone}>
              <span className="text-sm my-auto text-white font-semibold">Επαλήθευση</span>
            </button>
              </div>
            </div>
          </div>

          {/* Κουπόνι */}
          <div className="flex flex-col w-full lg:w-[70%] md:w-[70%] mt-4">
            <div className="flex flex-row w-full">
            <label className="flex mb-2 text-sm font-medium text-gray-900 dark:text-white">Έχετε κουπόνι;</label>
            <FaSquarePlus  className="ml-5 mt-0.5 size-5 hover:cursor-pointer text-blue-700 hover:text-blue-400" onClick={e=>setCoupon(!coupon)}/>
            </div>
              <div className={` ${coupon ? "flex flex-col" : "invisible w-0 h-0"}`}>
                <div className="flex lg:flex-row  md:flex-row flex-col ">
                  <input type="text" name='coupon' onChange={e=>setCoupon(e.target.value)} placeholder="Το κουπόνι σας" className="bg-gray-50 border mr-4 flex border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required/>
                  <button className=" lg:mt-0 md:mt-0 mt-3 lg:w-36 lg:h-[44px] w-24 h-10 lg:px-3 lg:py-2 px-2 py-2  flex rounded-md bg-blue-700 text-sm hover:bg-blue-400" type="button" onClick={validateCoupon}>
                    <span className="text-sm my-auto text-white font-semibold">Επαλήθευση</span>
                  </button>
               </div>
              </div>
          </div>
          </div>

           {/* Όροι χρήσης, πολιτική απορρήτου */}
          <div className="flex items-start mt-10">
              <div className="flex items-center h-5">
                  <input type="checkbox" className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800" required/>
                </div>
                <div className="ml-3 text-sm">
                    <label className="text-gray-500 dark:text-gray-300 text-[15px]">Αποδοχή των
                      <a href="#" className="text-[15px] font-medium text-[#1a97af] hover:underline dark:text-[#1a97af]"> Όρων Χρήσης και Πολιτικής Απορρήτου</a>
                    </label>
                  </div>
                </div>
          <div className="flex mt-5 w-full">
            <button type="submit" className=" w-full flex flex-row float-center justify-center bg-[#186c2a] hover:bg-[#186c2ab2] text-white font-semibold py-3 px-3 border rounded-2xl">
              <span className="text-sm">Δημιουργία λογαριασμού</span>
            </button>
          </div>
          <div className="flex mt-5 w-full border-t justify-center justify-items-center items-center py-10">
            <p className="text-[14px] font-light text-[#1a97af] dark:text-[#1a97af] hover:underline">
              Έχετε ήδη λογαριασμό; <Link to="/login" className="font-medium text-primary-600 hover:underline dark:text-primary-500">Συνδεθείτε στο Timologise.gr !</Link>
            </p>
          </div>
            
      </div>

      </Form>
    </div>



    </div>
  )
}

export default Register