import React from 'react'

function ErrorPage({ title, message, onConfirm, open }) {
  return (
    <div className={`${open ? "fixed inset-0 flex p-5 justify-center justify-items-center overflow-y-auto bg-[#00000046] w-full max-h-screen" : "w-screen max-h-0 invisible"}`}>
      <div className={`${open ? "w-[700px] h-[200px] flex flex-col justify-center justify-items-center mt-20 bg-red-400 rounded-md" : "max-h-0 invisible"}`}>
      <div className="bg-red-400 mt-5  w-full justify-start justify-items-start pl-4 h-auto rounded-lg">
        <h2 className="text-xl font-semibold text-white mt-2">{title}</h2>
        <p className="mt-1 mb-2 text-white">{message}</p>
        {onConfirm && (
          <div id="confirmation-actions">
            <button onClick={onConfirm} className="border-2 font-medium border-white px-2 py-2 mb-2 text-white hover:bg-white hover:text-red-400">
              Εντάξει
            </button>
          </div>
        )}
      </div>
      </div>
      </div>
  );
}

export default ErrorPage