import React from 'react'
import {useState ,useRef,useEffect} from 'react'
import { IoMdClose } from "react-icons/io";
import { IoSend } from "react-icons/io5";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import Kwdikosinfo from '../../../../../components/info/parametroi/kathgorieseidwn/Kwdikosinfo';
import PerigrafhInfo from '../../../../../components/info/parametroi/kathgorieseidwn/PerigrafhInfo';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import CodeExists from '../../../../../components/info/parametroi/kathgorieseidwn/CodeExists';
import NameCodeCheck from '../../../../../components/info/parametroi/kathgorieseidwn/NameCodeCheck';
import ErrorPage from '../../../../../components/info/parametroi/stoixeia-etaireias/ErrorPage';

function Addnew({ open, onClose, ids}) {
  {/* Αρχικοποίηση μεταβλητών */}
  const [codeInfo,setcodeInfo] = useState(false);
  const [perigrafhInfo,setperigrafhInfo] = useState(false);
  const [code, setCode] = useState();
  const [name,setName] = useState();
  const navigate = useNavigate();
  const [openError, setOpenError] = useState(false);
  const [error,setError] = useState();
  const [csrf,setCsrf] = useState();
  const [openDial,setOpenDial] = useState(false);
  const [data,setData] = useState();
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  let menuRef = useRef();
  let perigrafhRef = useRef();
  useEffect(() => {

    let handler = (e) => {
      if(!menuRef?.current?.contains(e.target)){
        setcodeInfo(false);
      }
      
    }

    document.addEventListener("mousedown",handler);

  });

  useEffect(()=> {
    let handler = (e) => {
      
      if(!perigrafhRef?.current?.contains(e.target)){
        setperigrafhInfo(false);
      }
      
    }

    document.addEventListener("mousedown",handler);
   
  })

  useEffect(()=>{
    const getCsrfToken = async () =>{
      try {
        await axios.get(BASE_URL + '/csrf-token', { withCredentials: true })
          .then(response => {
            setCsrf(response.data.csrfToken);
            
          })
          .catch(error => {
            console.error('Error fetching CSRF token:', error);
          });
      } catch (error) {
        console.error('Error fetching CSRF token:', error);
      }
    }
    getCsrfToken();
  },[])

    const checkId = Object.values(ids).filter(item=>item.code===`${code}`).length>0;

    const onMyClose = ()=>{
      setCode('');
      setName('');
      setOpenError(false);
      setError(null);
      onClose();
     
      
    }

    const handleError = () =>{
      setError(null);
      setOpenDial(false);
    }


    {/* Καταχώρηση νέας εγγραφής μέσω axios  */}
  const submitClick = async(e)=>{
    e.preventDefault();
    console.log(checkId);
    try {
      if(!checkId){
        await axios.post(BASE_URL + '/parametroi/kathgories-eidwn/',{name,code},{
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': csrf,
        },
          withCredentials: true})
      
        .then(res=>{
          onMyClose();
          setOpenError(false);
          console.log(checkId);
        })
        .catch(err=>{
          setError(err.response.data.errors);
          //throw new Error('Σφάλμα κατά την προσθήκη νέας κατηγορίας...')
        });
      
      } else{
        console.log("error");
        setOpenError(true);
        console.log(checkId);
      }
     
    } catch (error) {
      
      setOpenDial(true);
    }
     
    
       
  }

 {/* Αρχικό div του modal */}

  return (
    <div className={`${open ? "fixed inset-0 flex p-5 justify-center justify-items-center overflow-y-auto bg-[#00000046] w-full max-h-screen" : "w-screen max-h-0 invisible"}`}>
    {/* {error && <ErrorPage title="Προέκυψε σφάλμα" message={error.message} onConfirm={handleError} open={openDial} />} */}

      <div className={`${open ? "w-[700px] h-[410px] flex flex-col justify-center justify-items-center mt-20 bg-white rounded-md" : "max-h-0 invisible"}`}>
        <div className="flex flex-row justify-between justify-items-between border-b shadow-md ">
        <h1 className="flex text-left p-4 rounded font-semibold text-xl my-auto">Νέα Κατηγορία</h1>
        <IoMdClose className="cursor-pointer flex text-3xl text-black mt-4 mr-2" onClick={()=>onMyClose()}/>
        </div>

        <div>
                  {error &&
                  ( <ul className="mt-2 mx-2 flex flex-col justify-items-start justify-start items-start align-middle">
                    {Object.values(error).map((err)=> (
                    <li className= "text-red-500 text-[15px]" key={err}>{err}</li>           
                    ))}
                  </ul>
                )}
        </div>
      
      <form className="flex flex-col md:ml-10 lg:ml-10" onSubmit={submitClick}>
      <label className="flex flex-col justify-items-start justify-start text-left mt-10 ml-5 ">
      <span className="flex text-black text-[15px] font-medium mb-2">Κωδικός</span>
      <div className="flex flex-row" ref={menuRef}>
      <div className="flex flex-col"></div>
      <input type="number" value={code || ''} onWheel={ event => event.currentTarget.blur() } onChange={e => setCode(e.target.value)} className="px-2 flex-row h-7 w-80 text-sm bg-slate-50 border-2 border-gray-300 ml-3 outline-none rounded-md  [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none" required/>
      <AiOutlineExclamationCircle className="hover:text-slate-300 cursor-pointer mt-1 ml-1" onClick={()=>setcodeInfo(!codeInfo)}/>
      <Kwdikosinfo flag={codeInfo}/>
      <div className="flex">
      <CodeExists open={openError}/>
      
      </div>
      
      </div>
      </label>
      <label className="flex flex-col justify-items-start justify-start text-left mt-10 ml-5">
      <span className="flex text-black text-[15px] font-medium mb-2">Περιγραφή</span>
      <div className="flex flex-row " ref={perigrafhRef}>
      <input type="text"  value={name || ''} maxLength="50" onChange={e=> setName(e.target.value)} className=" px-2 flex h-7 w-80 text-sm bg-slate-50 border-2 border-gray-300 ml-3 outline-none rounded-md " required/>
      <AiOutlineExclamationCircle className="hover:text-slate-300 cursor-pointer mt-1 ml-1"  onClick={()=>setperigrafhInfo(!perigrafhInfo)}/>
      <PerigrafhInfo flag={perigrafhInfo}/>
      </div>
      </label>
      
      

      <div className="p-3 flex flex-row justify-end justify-items-end mt-10">
      <button type="button" className=" justify-items-end text-left justify-right mr-5 bg-[#a6acac] hover:bg-[#a6acaca3] text-white font-semibold py-2 px-4 border rounded" onClick={()=>onMyClose()}>
        Ακύρωση
      </button>
      <button className=" justify-items-end text-left justify-right bg-[#1a97af] hover:bg-[#70b7c57a] text-white font-semibold py-2 px-4 border rounded">
        Καταχώρηση
      </button>
      </div>
      </form>
      
     </div>
        
    </div>
  )
}

export default Addnew