import {redirect} from 'react-router-dom';
import axios from 'axios'


export async function checkAuth(){
    
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    try{

       await axios.get(BASE_URL + '/auth',{withCredentials:true})
       .then()
       .catch();
       
    } catch (error){
        console.log("I am here");
        return redirect("/login");

    }
    
    return null;
}



