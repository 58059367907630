import React from 'react'
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io";
import * as BsIcons from "react-icons/bs";
import * as BiIcons from "react-icons/bi";

export const SideMenu = [
  {
  title: "Αρχική",
  path: "/",
  icon: <AiIcons.AiFillHome />,
  cName: "nav-text",
  },
  {
  title: "Παραστατικά",
  path:"/parastatika",
  icon: <IoIcons.IoIosPaper />,
  cName: "nav-text",
  },
  {
  title: "Πελάτες",
  path: "/pelates",
  icon: <BsIcons.BsFillPeopleFill />,
  cName: "nav-text",
  },
  {
  title: "Είδη/Υπηρεσίες",
  path: "/eidh-yphresies",
  icon: <BsIcons.BsBoxSeam />,
  cName: "nav-text",
  },
  {
  title: "Αναφορές",
  icon: <IoIcons.IoMdFolderOpen />,
  cName: "nav-text",
  submenu: [
    {
      title: "Στατιστικά",
      path: "/anafores/statistika/",
      icon:"",
      cName: "nav-text",
    },
    {
      title: "Ημερολόγιο Πωλήσεων",
      path: "/anafores/hmerologio-pwlhsewn",
      icon:"",
      cName: "nav-text",
    },
    {
      title: "Πωλήσεις ανά είδος",
      path: "/anafores/pwlhseis-ana-eidos",
      icon: "",
      cName: "nav-text",
    },
    {
      title: "Υπόλοιπα Πελατών",
      path: "/anafores/ypoloipa-pelatwn",
      icon:"",
      cName: "nav-text",
    },
    {
      title: "Συσχέτιση με myData",
      path: "/anafores/sysxetish-mydata",
      icon:"",
      cName: "nav-text",
    },
    {
      title: "Πρόβλεψη ΦΠΑ",
      path: "/anafores/provlepsh-fpa",
      icon:"",
      cName: "nav-text",
    },
    {
      title: "Εξαγωγή Αρχείου γιά Λογιστή",
      path: "/anafores/exagogh-arxeiou-gia-logisth",
      icon:"",
      cName: "nav-text",
    } 
  ],
  },
  {
  title: "E-shop",
  icon: <BsIcons.BsGlobe />,
  cName: "nav-text",
  submenu: [
    {
      title: "Έλεγχος Λήξης Συνδρομών",
      path: "/eshop/elegxos-lhxhs-syndromwn",
      icon:"",
      cName: "nav-text",
    },
    {
      title: "Ανανέωση Συνδρομής",
      path: "/eshop/ananewsh-sindromis",
      icon:"",
      cName: "nav-text",
    },
    {
      title: "Αγορά σύνδεσης με Πάροχο",
      path: "/eshop/agora-shndeshs-me-paroxo",
      icon:"",
      cName: "nav-text",
    },
    {
      title: "Αγορά σύνδεσης με POS",
      path: "/eshop/agora-syndeshs-me-pos",
      icon: "",
      cName: "nav-text",
    },
    {
      title: "Αγορά άδειας mobile",
      path: "/eshop/agora-adeias-mobile",
      icon:"",
      cName: "nav-text",
    },
    {
      title: "Αγορά SMS & Email",
      path: "/eshop/agora-sms-email",
      icon:"",
      cName: "nav-text",
    },
  ],
  },
  {
  title: "Παράμετροι",
  icon : <BsIcons.BsDatabase />,
  cName: "nav-text",
  submenu: [
    {
      title: "Στοιχεία Εταιρείας",
      path: "/parametroi/stoixeia-etaireias",
      icon:"",
      cName: "nav-text",
    },
    
    {
      title: "Ρυθμίσεις Παραστατικών",
      path: "/parametroi/rythmiseis-parastatikwn",
      icon: "",
      cName: "nav-text",
    },
    {
      title: "Παράμετροι POS",
      path: "/parametroi/parametroi-pos",
      icon:"",
      cName: "nav-text",
    },
    {
      title: "Παράμετροι Παρόχων",
      path: "/parametroi/parametroi-paroxwn",
      icon:"",
      cName: "nav-text",
    },
    {
      title: "Κατηγορίες Ειδών",
      path: "/parametroi/kathgories-eidwn",
      icon:"",
      cName: "nav-text",
    },
    {
      title: " Γενικές Παράμετροι",
      path: "/parametroi/genikes-parametroi",
      icon:"",
      cName: "nav-text",
    } 
  ],
  },
];