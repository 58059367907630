import React from 'react'
import { useState, useEffect } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom';
import { IoMdClose } from "react-icons/io";
import { IoWarning } from "react-icons/io5";
import ErrorPage from '../../../../../components/info/parametroi/stoixeia-etaireias/ErrorPage';

function Delete({open, onClose, id}) {

  const [error,setError] = useState();
  const [openError,setOpenError] = useState(false);
  const [csrf,setCsrf] = useState();
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const handleError = ()=>{
    setError(null);
    setOpenError(false);
  }

{/* Get CSRF Token */}
useEffect(()=>{
  const getCsrfToken = async () =>{
    try {
      await axios.get(BASE_URL + '/csrf-token', { withCredentials: true })
        .then(response => {
          setCsrf(response.data.csrfToken);
          
        })
        .catch(error => {
          console.error('Error fetching CSRF token:', error);
        });
    } catch (error) {
      console.error('Error fetching CSRF token:', error);
    }
  }
  getCsrfToken();
},[])





{/* Διαγραφή κατηγορίας με βάση το code */}
const deleteTypos = async (id) => {
    try {
      await axios.delete(BASE_URL + '/parametroi/rythmiseis-parastatikwn/' + id, {
        headers: {
          'X-CSRF-Token': csrf,
        },
        withCredentials: true
      })
        .then(res =>{
            onClose();
        })
        .catch(err => {
          throw new Error('Προέκυψε σφάλμα κατά τη σύνδεση με τον server')
        });
    } catch (error) {
      setError(error);
    }
  }

  return (
    <div className={`${open ? "fixed inset-0 flex p-5 justify-center justify-items-center overflow-y-auto bg-[#00000046] w-full h-screen" : " overflow-y-hidden max-h-0 invisible"}`}>
       {error && <ErrorPage title="Προέκυψε σφάλμα..." message={error.message}  onConfirm={handleError} open={openError} /> }
        <div className={`${open ?"w-[500px] h-[300px] flex flex-col  mt-20 bg-white rounded-md": "max-h-0 invisible"}`}>
            <div className="flex flex-row justify-between justify-items-between border-b shadow-md ">
            <h1 className="flex text-left p-4 rounded font-semibold text-xl ">Επιβεβαίωση διαγραφής</h1>
            <IoMdClose className="cursor-pointer flex text-3xl text-black mt-4 mr-2" onClick={()=>onClose()}/>
            </div>
            <div className="flex flex-col my-auto text-pretty mx-auto justify-items-center justify-center ">
            <p className=" flex font-normal mb-5 lg:px-8 md:px-8 w-full">Είστε σίγουρος για την οριστική διαγραφή του παραστατικού;</p>
            {/*<span className=" flex font-medium justify-center justify-items-center text-xl">{id}</span>*/}
            <div className="flex flex-row mx-auto mt-3">
            <IoWarning className="size-4 flex justify-items-center my-auto w-6 h-6 mr-3 text-red-300" />
            <span className="my-auto flex justify-center justify-items-center text-sm text-gray-500 font-medium">Η ενέργεια αυτή δεν μπορεί να αναιρεθεί</span>
            </div>
            </div>
        <div className="p-3 flex flex-row justify-center justify-items-center">
        <button className=" justify-items-end text-left justify-right mr-5 bg-slate-400 hover:bg-slate-300 text-white font-semibold py-2 px-4 border rounded" onClick={()=>onClose()}>
        Ακύρωση
        </button>
      <button className=" justify-items-end text-left justify-right bg-red-500 hover:bg-red-400 text-white font-semibold py-2 px-4 border rounded" onClick={()=>deleteTypos(id)}>
        Διαγραφή
      </button>
      </div>
        </div>
    </div>
  )
}

export default Delete