import React from 'react';
import Header from './Header';
import {Outlet} from  'react-router-dom';
import MenuItems from './MenuItems';

function MainScreen() {
  return (
    <div className = "flex flex-row w-full h-full bg-slate-200">
      <div className="flex">
      <MenuItems />
      </div>
      <div className="flex flex-col w-full overflow-y-auto h-screen">
        <Header/>
        <div className="flex">
        <Outlet />
        </div>
      </div>
     
      </div>
      
      
   
    
  );
};

export default MainScreen