import React from 'react'
import { IoMdPerson, IoIosLogOut } from "react-icons/io";


export const SettingsDropDownHeader = [

    {
        title: 'Προφίλ',
        path: '/profile',
        icon: <IoMdPerson className="size-3.5"/>,
        cName: 'nav-text',
    },

];
