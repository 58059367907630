import React from 'react'
import {useState ,useRef,useEffect} from 'react'
import { IoMdClose } from "react-icons/io";
import { IoSend } from "react-icons/io5";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import Kwdikosinfo from '../../../../../components/info/parametroi/kathgorieseidwn/Kwdikosinfo';
import PerigrafhInfo from '../../../../../components/info/parametroi/kathgorieseidwn/PerigrafhInfo';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import DescriptionRequired from '../../../../../components/info/parametroi/kathgorieseidwn/DescriptionRequired';
import ErrorPage from '../../../../../components/info/parametroi/stoixeia-etaireias/ErrorPage';

function Update({ open, onClose, data}) {
  {/* Αρχικοποίηση μεταβλητών */}
  const [codeInfo,setcodeInfo] = useState(false);
  const [perigrafhInfo,setperigrafhInfo] = useState(false);
  const [code, setCode] = useState('');
  const [name,setName] = useState('');
  const [openDescr,setOpenDescr] = useState(false);
  const [error,setError] = useState();
  const [csrf,setCsrf] = useState();
  const [openError,setOpenError] = useState(false);
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const id = data.id;
  const navigate = useNavigate();
  let perigrafhRef = useRef();

  const handleError = ()=>{
    setError(null);
    setOpenError(false);
  }

  {/* Get CSRF Token */}
  useEffect(()=>{
    const getCsrfToken = async () =>{
      try {
        await axios.get(BASE_URL + '/csrf-token', { withCredentials: true })
          .then(response => {
            setCsrf(response.data.csrfToken);
            
          })
          .catch(error => {
            console.error('Error fetching CSRF token:', error);
          });
      } catch (error) {
        console.error('Error fetching CSRF token:', error);
      }
    }
    getCsrfToken();
  },[])
 
{/* Enable and disable info text for description */}
  useEffect(()=> {
    let handler = (e) => {
      
      if(!perigrafhRef?.current?.contains(e.target)){
        setperigrafhInfo(false);
      }
      
    }

    document.addEventListener("mousedown",handler);
   
  })

  const onMyClose = ()=>{
    setName('');
    onClose();
    setOpenDescr(false);
  }
 
 
  
  const submitClick = async()=>{
   
    try {
      if(name!=''){
        await axios.put(BASE_URL + '/parametroi/kathgories-eidwn/' + id,{name},{
          headers: {
          'X-CSRF-TOKEN': csrf,
         },
         withCredentials: true})
        .then(res=>{
          onMyClose();
          
        }).catch(err=>{
          throw new Error('Σφάλμα κατά την ενημέρωση της κατηγορίας...')});
       } else{
        console.log("error");
        setOpenDescr(true);
       }
    } catch (error) {
      setError(error);
      setOpenError(true);
    }
   
   
  
  }

  return (
    <div className={`${open ? "fixed inset-0 flex p-5 justify-center justify-items-center overflow-y-auto bg-[#00000046] w-full max-h-screen" : "w-screen max-h-0 invisible"}`}>
        {error && <ErrorPage title="Προέκυψε σφάλμα..." message={error.message}  onConfirm={handleError} open={openError} /> }
      <div className={`${open ? "w-[700px] h-[380px] flex flex-col justify-center justify-items-center mt-20 bg-white rounded-md" : "max-h-0 invisible"}`}>
        <div className="flex flex-row justify-between justify-items-between border-b shadow-md ">
        <h1 className="flex text-left p-4 rounded font-semibold text-xl ">Ενημέρωση κατηγορίας</h1>
        <IoMdClose className="cursor-pointer flex text-3xl text-black mt-4 mr-2" onClick={()=>onMyClose()}/>
        </div>
      
      
      <div className="flex flex-col md:ml-10 lg:ml-10 ">
      <label className="flex flex-col justify-items-start justify-start text-left mt-10 ml-5 ">
      <span className="flex text-black text-[15px] font-medium mb-2">Κωδικός</span>
      <div className="flex flex-row">
      <input type="number" value={data?.code || ''} onWheel={ event => event.currentTarget.blur() } required readOnly ="readonly" className="flex-row h-7 w-80 text-sm bg-slate-200 border-2 border-gray-300 ml-3 outline-none rounded-md duration-200 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"/>
      
      </div>
      </label>
      <label className="flex flex-col justify-items-start justify-start text-left mt-10 ml-5">
      <span className="flex text-black text-[15px] font-medium mb-2">Περιγραφή</span>
      <div className="flex flex-row " ref={perigrafhRef}>
      <input type="text" maxLength="50" value={name || ''} onChange={e=> setName(e.target.value)} placeholder={data?.name} required="required" className=" flex h-7 w-80 text-sm bg-slate-200 border-2 border-gray-300 ml-3 outline-none rounded-md duration-200"/>
      <AiOutlineExclamationCircle className="hover:text-slate-300 cursor-pointer mt-1 ml-1"  onClick={()=>setperigrafhInfo(!perigrafhInfo)}/>
      <PerigrafhInfo flag={perigrafhInfo}/>
      <DescriptionRequired open={openDescr} />
      </div>
      </label>
      </div>
      

      <div className="p-3 flex flex-row justify-end justify-items-end mt-10">
      <button className=" justify-items-end text-left justify-right mr-5 bg-[#a6acac] hover:bg-[#a6acaca3] text-white font-semibold py-2 px-4 border rounded" onClick={()=>onMyClose()}>
        Ακύρωση
      </button>
      <button className=" justify-items-end text-left justify-right bg-[#1a97af] hover:bg-[#70b7c57a] text-white font-semibold py-2 px-4 border rounded" onClick={()=>submitClick()}>
        Ενημέρωση
      </button>
      </div>
      </div>
        
        
    </div>
  )
}

export default Update