import './App.css';
import {useState, useEffect} from 'react'
import MainActivity from './screens/activities/MainActivity';
import {BrowserRouter, Routes, Route, createBrowserRouter, RouterProvider} from  'react-router-dom';
import {checkAuth} from './auth/auth';

import MenuItems from './screens/MenuItems';
import Login from './screens/Login';
import Register from './screens/Register';
import Profile from './screens/Profile';
import ErrorElement from './ErrorElement';

import MainScreen from './screens/MainScreen';
import Parastatika from './screens/activities/Parastatika';
import EidhYphresies from './screens/activities/EidhYphresies';
import Anafores from './screens/activities/Anafores';
import Pelates from './screens/activities/Pelates';
import Eshop from './screens/activities/Eshop';
import Parametroi from './screens/activities/Parametroi';
import Statistika from'./screens/activities/anafores/Statistika';

import HmerologioPwlhsewn from'./screens/activities/anafores/HmerologioPwlhsewn';
import ProvlepshFpa from'./screens/activities/anafores/ProvlepshFpa'; 
import ExagoghArxeiouLogisth from'./screens/activities/anafores/ExagoghArxeiouLogisth'; 
import PwlhseisAnaEidos from'./screens/activities/anafores/PwlhseisAnaEidos';
import SysxetishMyData from'./screens/activities/anafores/SysxetishMyData'; 
import YpoloipaPelatwn from'./screens/activities/anafores/YpoloipaPelatwn';

import AgoraMeParoxo from './screens/activities/eshop/AgoraMeParoxo';
import AgoraMePos from './screens/activities/eshop/AgoraMePos';
import AgoraMobile from './screens/activities/eshop/AgoraMobile';
import AgoraSMSEmail from './screens/activities/eshop/AgoraSMSEmail';
import AnanewshSyndromhs from './screens/activities/eshop/AnanewshSyndromhs';

import ElegxosSyndromwn from './screens/activities/parametroi/ElegxosSyndromwn';
import GenikesParametroi from './screens/activities/parametroi/GenikesParametroi';
import KathgoriesEidwn from './screens/activities/parametroi/KathgoriesEidwn';
import ParametroiParoxwn from './screens/activities/parametroi/ParametroiParoxwn';
import ParametroiPos from './screens/activities/parametroi/ParametroiPos';
//import ParametroiTypoiParastatikwn from './screens/activities/parametroi/rythmiseis_parastatikwn/RythmiseisParastatikwn';
import StoixeiaEtaireias from './screens/activities/parametroi/StoixeiaEtaireias';
import RythmiseisParastatikwn, {loader, loader as rythmiseisLoader} from './screens/activities/parametroi/rythmiseis_parastatikwn/RythmiseisParastatikwn';
import RythmishParastatikou from './screens/activities/parametroi/rythmiseis_parastatikwn/RythmishParastatikou';
import AddRythmishParastatikou from './screens/activities/parametroi/rythmiseis_parastatikwn/AddRythmishParastatikou';
  
  

function App() {
const router = createBrowserRouter([
  {
    path: "/",
    element: <MainScreen />,
    loader: checkAuth,
    errorElement: <ErrorElement />,
    children: [
      {index: true, element: <MainActivity />},
      
      {
        path: "parastatika",
        element: <Parastatika />,
        loader: checkAuth,
      },
      {
        path: "pelates",
        element: <Pelates />,
        loader: checkAuth,
      },
      {
        path: "eidh-yphresies",
        element: <EidhYphresies />,
        loader: checkAuth,
      },
      {
        path: "profile",
        element: <Profile />,
        loader: checkAuth,
      },
      {
        path: "anafores",
        loader: checkAuth,
        children: [
          {
            path:"statistika",
            element: <Statistika />,
            
          },
          {
            path: "hmerologio-pwlhsewn",
            element: <HmerologioPwlhsewn />,
            
          },
          {
            path: "pwlhseis-ana-eidos",
            element: <PwlhseisAnaEidos />,
            
          },
          {
            path: "ypoloipa-pelatwn",
            element: <YpoloipaPelatwn />,
            
          },
          {
            path: "sysxetish-mydata",
            element: <SysxetishMyData />,
            
          },
          {
            path: "provlepsh-fpa",
            element: <ProvlepshFpa />,
            
          },
          {
            path: "exagogh-arxeiou-gia-logisth",
            element: <ExagoghArxeiouLogisth />,
            
          },
        ],
      },
      {
        path: "eshop",
        loader: checkAuth,
        children: [
          {
            path: "elegxos-lhxhs-syndromwn",
            element: <ElegxosSyndromwn />,
            
          },
          {
            path: "ananewsh-sindromis",
            element: <AnanewshSyndromhs />,
            
          },
          {
            path: "agora-shndeshs-me-paroxo",
            element: <AgoraMeParoxo />,
            
          },
          {
            path: "agora-syndeshs-me-pos",
            element: <AgoraMePos />,
            
          },
          {
            path: "agora-adeias-mobile",
            element: <AgoraMobile />,
            
          },
          {
            path: "agora-sms-email",
            element: <AgoraSMSEmail />,
            
          },

        ],
      },
      {
        path: "parametroi",
        loader: checkAuth,
        children: [

          {
            path: "stoixeia-etaireias",
            element: <StoixeiaEtaireias />,
          },
          {
            path: "rythmiseis-parastatikwn",
            id: "rythmiseis-parastatikwn",
            loader: rythmiseisLoader,
            children: [
              {
                index: true,
                element: <RythmiseisParastatikwn />,
              },
              {
              path: "nea_eggrafh",
              element: <AddRythmishParastatikou />,
              },
              {
              path: ":id",
              element: <RythmishParastatikou />,
              },
          ],
        },
         
          {
            path: "parametroi-pos",
            element: <ParametroiPos />,
            
          },
          {
            path: "parametroi-paroxwn",
            element: <ParametroiParoxwn />,
           
          },
          {
            path: "kathgories-eidwn",
            element: <KathgoriesEidwn />,
           
          },
          {
            path: "genikes-parametroi",
            element: <GenikesParametroi />,
            
          },
        ],
      }
    ],
  },
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/register',
    element: <Register />
  },

])

function loadScriptByURL(id, url, callback) {
  const script = document.createElement("script");
  script.id = id;
  script.src = url;
  script.async = true;
  script.defer = true;
  script.onload = callback;
  document.body.appendChild(script);
}

useEffect(() => {
  const SITE_KEY = process.env.REACT_APP_DOCON_RECAPTCHA_SITE_KEY;

  loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`, function () {
    console.log("Script loaded!");
    if (typeof grecaptcha !== "undefined") {
      console.log("grecaptcha is defined");
    } else {
      console.log("grecaptcha is not defined");
    }
  });
}, []);


  return (
    
   
    
    <RouterProvider router={router} />

    
   
   
    
    
  );
}

export default App;
