import React from 'react'
import { useParams, Form, useNavigate, useRouteLoaderData, useRevalidator } from 'react-router-dom'
import {useState, useEffect} from 'react'
import { IoMdSave } from "react-icons/io";
import { MdModeEdit } from "react-icons/md";
import { IoArrowBackSharp } from "react-icons/io5";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import axios from 'axios';

function RythmishParastatikou() {
    const params = useParams();
    const id = params.id;
    console.log(id);
    const [edit,setEdit] = useState(true);
    const navigate = useNavigate();
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const [typoi,setTypoi] = useState(useRouteLoaderData('rythmiseis-parastatikwn'));
    const [typos_perigrafh,setTyposPerigrafh] = useState('');
    const [error,setError] = useState();
    const [openError,setOpenError] = useState(false);
    const [typos_id,setTyposId] = useState('');
    const [seira,setSeira] = useState('');
    const [enarksh_aa,setEnarkshAa] = useState('');
    const [perigrafh,setPerigrafh] = useState('');
    const [csrf,setCsrf] = useState('');
    const [openDial,setOpenDial] = useState(false);
    const [isUsed,setIsUsed] = useState(false);
    const revalidator = useRevalidator();

    const handleError = ()=>{
      setError(null);
      setOpenError(false);
    }
    
    useEffect(()=>{
      const getCsrfToken = async () =>{
        try {
          await axios.get(BASE_URL + '/csrf-token', { withCredentials: true })
            .then(response => {
              setCsrf(response.data.csrfToken);
              
            })
            .catch(error => {
              console.error('Error fetching CSRF token:', error);
            });
        } catch (error) {
          console.error('Error fetching CSRF token:', error);
        }
      }
      getCsrfToken();
    },[])


    const getRythmiseisParastatikwn = async ()=>{
      try{
        await axios.get(BASE_URL +'/parametroi/rythmiseis-parastatikwn',{withCredentials: true})
         .then(res=>typoi(res.data))
         .catch(err=>{
           throw new Error('Προέκυψε σφάλμα κατά τη σύνδεση με τον server');
         });
        } catch(error){
         setError(error);
         setOpenError(true);
        }
      }


    const onSubmitForm = async (e) => {
      e.preventDefault();
      revalidator.revalidate();
      try {
        await axios.put(BASE_URL + '/parametroi/rythmiseis-parastatikwn/' + id, {typos_id,seira,enarksh_aa,perigrafh,isUsed},{
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': csrf,
        },
         withCredentials: true})
        .then(res=> res.data && navigate('/parametroi/rythmiseis-parastatikwn'))
        .catch(err=>{
          setError(err.response.data.errors);
        });
      } catch (error) {
        setError(error.response.data.errors);

        
      }
      
    }

    const stepBack = () => {
      navigate('/parametroi/rythmiseis-parastatikwn');
      
    }

    const handleChange = (event) => {
      setTyposId(event.target.value);
    };

   useEffect(()=>{
    
    typoi.rythmiseisParastatikwn && typoi.rythmiseisParastatikwn.length > 0 &&
    typoi.rythmiseisParastatikwn.filter((d) =>String(d.id) === params.id)
    .map((d,i)=>(
      setTyposId(d.typos_id),
      setSeira(d.seira),
      setEnarkshAa(d.enarksh_aa),
      setPerigrafh(d.perigrafh),
      setIsUsed(d.isUsed)
    ))
    console.log(typoi)    
   },[])

  return (
    <div className="w-full shadow-md overflow-x-hidden flex flex-col justify-items-center p-10 text-center justify-center overflow-y-hidden bg-white mt-5 mx-4 rounded-md">
      
    
    <Form onSubmit = {e=>onSubmitForm(e)} encType = "application/json">
     <div className="w-full shadow-md overflow-x-hidden flex flex-col justify-items-center p-10 text-center justify-center overflow-y-hidden bg-white mt-5 mx-4 rounded-md">
     <div className="flex flex-col border-[#d1d3e2] border-b shadow-md rounded-md h-[100px]">
      <h1 className="lg:text-2xl text-[#3a3b45] font-semibold flex justify-center">Επεξεργασία Τύπου #{params.id}</h1>
      </div>

      <div className="flex justify-center mt-2">
      {error &&
                  ( <ul className="mt-2 mx-2 flex flex-col justify-items-start justify-start items-start align-middle">
                    {Object.values(error).map((err)=> (
                    <li className= "text-red-500 text-[15px]" key={err}>{err}</li>           
                    ))}
                  </ul>
                )}
      </div>
     
      {typoi.rythmiseisParastatikwn && typoi.rythmiseisParastatikwn.length>0 ? (
        Object.values(typoi.rythmiseisParastatikwn).filter((d) =>String(d.id) === params.id)
        .map((d,i)=>(
       
          <div className="flex-col mt-10 md:w-full lg:w-full" key={i}>
          {/* Div */}    
          <div className="flex flex-col md:flex md:flex-row lg:flex lg:flex-row md:w-full lg:w-full">
          <label className=" flex justify-items-start justify-left text-left w-[40%]">
          <span className="flex justify-items-start text-red-500">* <span className=" ml-1 flex text-black text-[15px] font-normal ">Τύπος Παραστατικού :</span></span>
          </label>

          <Box className="w-full mt-2">
          <FormControl fullWidth>
            <Select
              id="typos_selection_select"
              value={typos_id || ''}
              disabled={edit}
              onChange={e=>setTyposId(e.target.value)}
              className={` ${edit ? "bg-slate-200": "bg-white"} flex h-9 text-sm  focus:border-4 border-2 border-[#d1d3e2] focus:outline-none focus:border-[#70b7c5] focus:shadow-md ml-3 outline-none rounded-md`}
              >
              {Object.values(typoi.typoi_parastatikwn.data).map((d,i) => (
                <MenuItem key={i} value={d.typos_parastatikou}>{d.typos_parastatikou}</MenuItem>
                ))}
            </Select>
          </FormControl>
          </Box>
          </div>

          {/* Div σειράς*/} 
          <div className="flex flex-col md:flex md:flex-row lg:flex lg:flex-row md:w-full lg:w-full mt-8">
          <label className="flex justify-items-start justify-left text-left w-[40%]">
          <span className="flex justify-items-start text-red-500">* <span className=" ml-1 flex text-black text-[15px] font-normal ">Σειρά :</span></span>
          </label>
          <input type="text" disabled={edit}  maxLength="10" defaultValue={d.seira || ''} onChange={e=>setSeira(e.target.value)} placeholder="Η σειρά" className={` ${edit ? "bg-slate-200": "bg-white"} px-3 flex h-9 w-full text-[16px]  focus:border-4 border-2 border-[#d1d3e2] focus:outline-none focus:border-[#70b7c5] focus:shadow-md ml-3 outline-none rounded-md `}required />
          </div>

           {/* Div έναρξης*/} 
          <div className="flex flex-col md:flex md:flex-row lg:flex lg:flex-row md:w-full lg:w-full mt-8">
          <label className=" flex justify-items-start justify-left text-left md:w-[40%] lg:w-[40%] w-full">
          <span className="flex justify-items-start text-red-500">* <span className=" ml-1 flex text-black text-[15px] font-normal ">Έναρξη :</span></span>
          </label>
          <input type="number" disabled={edit}  defaultValue={d.enarksh_aa || ''} onChange={e=>setEnarkshAa(e.target.value)} placeholder="Ο αριθμός έναρξης" className={` ${edit ? "bg-slate-200": "bg-white"} px-3 flex h-9 w-full text-[16px] border-2 focus:border-4 focus:outline-none focus:border-[#70b7c5] focus:shadow-md border-[#d1d3e2] ml-3 outline-none rounded-md `} />
          </div>

           {/* Div περιγραφής*/} 
          <div className="flex flex-col md:flex md:flex-row lg:flex lg:flex-row md:w-full lg:w-full mt-8">
          <label className=" flex justify-items-start justify-left text-left  w-[40%]">
          <span className="flex justify-items-start text-red-500">* <span className=" ml-1 flex text-black text-[15px] font-normal ">Περιγραφή :</span></span>
          </label>
          <input type="text" disabled={edit} maxLength="50" defaultValue={d.perigrafh || ''} onChange={e=>setPerigrafh(e.target.value)} placeholder="Η περιγραφή του συγκεκριμένου τύπου" className={` ${edit ? "bg-slate-200": "bg-white"} px-3 flex h-9 w-full text-[16px] border-2 focus:border-4 focus:outline-none focus:border-[#70b7c5] focus:shadow-md border-[#d1d3e2] ml-3 outline-none rounded-md `} required/>           
          
          </div>
        </div>
            
          ))
         ) : (
            <p className="text-red-500 text-2xl">Loading...</p>
          
          )}

      

      <div className="flex flex-col mt-10 w-full">
     <div className="flex flex-row justify-center lg:justify-end md:justify-end">
     <button className="bg-blue-500 w-25 h-10 flex flex-row mr-2 float-right justify-end hover:cursor-pointer hover:bg-blue-300 text-white font-semibold py-2 px-3 border rounded-md" onClick={()=>stepBack()}>
        <IoArrowBackSharp className="lg:size-4 size-6 my-auto"/>
        <span className="text-sm hidden lg:flex md:flex">Επιστροφή</span>
      </button>
      <button type="button" className=" w-25 h-10 mr-2 flex flex-row float-right justify-end bg-[#818a83] hover:bg-[#818a8381] text-white font-semibold py-2 px-3 border rounded-md" onClick={()=>setEdit(false)}>
        <MdModeEdit className="lg:size-4 size-6 my-auto"/>
        <span className="text-sm hidden lg:flex md:flex">Επεξεργασία</span>
        </button>
      <button disabled={edit} className={`${edit ? "bg-[#507d59]": "bg-[#186c2a]"} w-25 h-10 flex flex-row float-right justify-end  hover:bg-[#186c2a77] text-white font-semibold py-2 px-3 border rounded-md`}>
        <IoMdSave className="lg:size-4 size-6 my-auto"/>
        <span className="text-sm hidden lg:flex md:flex">Καταχώρηση</span>
      </button>
      </div>
      </div>
        <div className="flex justify-start mt-2">
        <p className="flex-row font-thin text-sm mr-1 italic">Τα πεδία με ( <span className="text-red-400">*</span>  ) είναι υποχρεωτικά</p>
      </div>
     </div>
     </Form>
     </div>
      
  )
}

export default RythmishParastatikou