const isValidAFM = (afm) =>{
    if(afm.length > 9 || afm.length<9){
        return false;
    }

    return true;
}






export default {isValidAFM};