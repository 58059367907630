import React from 'react';
import {useState, useEffect} from 'react';
import {useNavigate, useActionData, Form, Link} from 'react-router-dom';
import axios from 'axios'
import ErrorPage from '../components/info/parametroi/stoixeia-etaireias/ErrorPage';
import { AiFillEye } from "react-icons/ai";
import { AiFillEyeInvisible } from "react-icons/ai";
import { getRecaptcha } from '../auth/reCaptcha/reCaptcha';

function Login() {
  const SITE_KEY = process.env.REACT_APP_DOCON_RECAPTCHA_SITE_KEY;
  const [company_id, setCompanyId] = useState('');
  const [initialEmail, setInitialEmail] = useState();
  const [password, setPassword] = useState('');
  const [emailLoaded, setEmailLoaded] = useState(false);
  const [checked, setChecked] = useState();
  const [error,setError] = useState();
  const [frontOpenError,setFrontOpenError] = useState();
  const [errorModal,setErrorModal] = useState();
  const [errorLogin,setErrorLogin] = useState(false);
  const [visiblePassword,setVisiblePassword] = useState(false);
  const navigate = useNavigate();
  const data = useActionData();
  const [showLoader, setShowLoader] = useState(false);
  const BASE_URL = process.env.REACT_APP_BASE_URL;


  const submitData = async (token) =>{
    try{
      
      await axios.post(BASE_URL+'/reCaptcha',{token},{withCredentials:true})
      .then(res=>{
        console.log(res.data.message);
      })
      .then(
        await axios.post(BASE_URL+'/login',{company_id,password}, {withCredentials: true})
        .then(res=>{
            if(res.status === 200){
                console.log(res.status);
                setShowLoader(false);
                navigate('/');
            }   
            
        })
        .catch(err=>{
            setError(err.response.data.errors);
            setFrontOpenError(err.response.data.message);
            setShowLoader(false);
        })
      )
      .catch(err=>{
        console.log(err);
        setShowLoader(false);
      })
     
     
  } catch(error) {  
      
      console.log(error);
      setShowLoader(false);
  }
  }


const token = getRecaptcha().then().catch();

  const onLogin = async (e) =>{
    e.preventDefault();
    setShowLoader(true);
    window.grecaptcha.ready(() => {
      window.grecaptcha.execute(SITE_KEY, { action: 'submit' }).then(token => {
        submitData(token);
      });
    });
   
    

  }

  const getLogin = async () => {
    try {
      await axios.get(BASE_URL+'/login')
    .then(res=> {
     
    })
    .catch(err=>{
      throw new Error('Δεν υφίσταται σύνδεση με τον server...');
    });
    } catch (error) {
        setErrorModal(error);
        setErrorLogin(true);
    }
    
  }
  const handleError = ()=>{
    setErrorModal(null);
    setErrorLogin(false);
  }

  
  const setMyCompanyId = (e) =>{
    setCompanyId(e.target.value);
    setFrontOpenError(false);
  }

  const setMyPassword = (e) =>{
    setPassword(e.target.value);
    setFrontOpenError(false);
  }

  useEffect(() => {
   getLogin();
  }, []);

    return (
    <div className="bg-[#1a97af] w-full h-screen">
    {errorModal && <ErrorPage title="Προέκυψε σφάλμα" message={errorModal.message} onConfirm={handleError} open={errorLogin} />}
    <Form className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-full lg:py-0" onSubmit={onLogin} >

       
      <div className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
         { /*  <img className="w-50 h-50 mr-2 bg-transparent" src={require('/Users/dai18/OneDrive/Έγγραφα/GitHub/topsoft_cloud/client/src/media/topsoft_logo.png')} alt="logo" />*/}
           
      </div>
      <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              
              <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                  Συνδεθείτε στον λογαριασμό σας
              </h1>

              <div>
                  {error &&
                    Object.values(error).map((err)=> (
                        <p className= "text-red-400 text-sm" key={err}>{err}</p>      
                  ))}
               </div>

              <div className={`${frontOpenError ? "flex w-full mb-3 h-auto" : "max-h-0 invisible max-w-0"}`}>            
                {frontOpenError && <p className="text-red-500 text-sm">{frontOpenError}</p> }
                </div>
              <div className="space-y-4 md:space-y-6">
                  <div>
                      <label  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Αναγνωριστικό εταιρείας</label>
                      <input type="text" onChange={setMyCompanyId} className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="256369859" required />
                  </div>
                  <div>
                      <label  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Κωδικός</label>
                      <div className="flex justify-between items-center focus-within:border-2  focus-within:outline-none border focus-within:border-gray-600 focus-within:shadow-md border-gray-300 outline-none rounded-lg h-10 p-2.5 w-full dark:bg-gray-700 dark:border-gray-600">
                      <input type={visiblePassword? "text" : "password"}  onChange={setMyPassword} placeholder="••••••••" className="w-full text-gray-900 border border-none focus:border-none focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
                        <div className="p-1 hover:cursor-pointer" onClick={()=>setVisiblePassword(!visiblePassword)}>
                          {visiblePassword ? <AiFillEye /> : <AiFillEyeInvisible />}
                        </div>
                      </div>
                   </div>
                  <div className="flex items-center justify-between">
                      <div className="flex items-start">
                      <Link to="/" className="text-sm font-medium text-primary-600 hover:underline dark:text-white">Ξέχασα τον κωδικό μου</Link>

                      </div>
                  </div>
                  <button  disabled={showLoader} type="submit"  className="w-full justify-center text-white bg-[#1a97af] hover:bg-[#60a6b4] focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 inline-flex items-center">
                    Σύνδεση
                    <svg aria-hidden="true" role="status" className={`${showLoader ? "inline w-4 h-4 me-3 ml-2 text-white animate-spin" : "max-h-0 max-w-0 invisible"}`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB"/>
                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor"/>
                    </svg>
                    </button>
                   {/* <button type="submit" className="w-full text-white bg-[#1a97af] hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Σύνδεση</button>*/}
                  <p className="text-sm font-light text-gray-500 dark:text-white">
                      Δημιουργήστε τον λογαριασμό σας <Link to="/register" className="font-medium text-primary-600 hover:underline dark:text-white">Εγγραφή</Link>
                  </p>
              </div>
          </div>
      </div>
  </Form>
</div>

    )
}

      

export default Login