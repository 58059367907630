import React from 'react';
import {useState} from 'react';
import {NavLink,useNavigate} from 'react-router-dom';
import { SettingsDropDownHeader } from './SettingsDropdownHeader';
import { IoIosLogOut } from "react-icons/io";
import axios from 'axios';

function SettingsHeaderMenu({open}) {
    const navigate = useNavigate();
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const  logout = async () => {
        
        try {
           await axios.get(BASE_URL+'/logout', {withCredentials: true})
            .then(res=>{
                if(res.status===200){
                    navigate('/login');
                }
            })
            .catch(err=>{
                throw new Error('Δεν υφίσταται σύνδεση με τον server...');
            })
        } catch (error) {
            console.log(error);
        }
      
    };


  return (
     <div className={`${open? " absolute z-10 lg:w-50 md:w-30 border border-gray-200 flex justify-items-end items-end justify-end lg:mt-[3%] mt-[11%] md:mt-[5.5%]" : "max-h-0 invisible max-w-0 border-none"} `}>
        <ul className={`${open? "pt-1 flex-col bg-white": "max-h-0 invisible"}  rounded-md transition-all duration-75 ease-in-out overflow-hidden`}> 
        {SettingsDropDownHeader.map((menu,index) => (
            <React.Fragment key = {index}>
            <li className="text-[#8c8d91] text-xs flex items-center gap-x-2 cursor-pointer p-2
             rounded-md my-2 hover:bg-slate-100 font-normal py-1 px-6"
             >
            <span className="text-sm float-left mt-1 mr-1 text-gray-600">
                {menu.icon}
            </span>
            <NavLink to={menu.path} className ="font-medium">
                {menu.title}
            </NavLink>
             </li>    
            </React.Fragment>
        ))}
        <li className="text-[#8c8d91] text-xs flex items-center gap-x-2 cursor-pointer p-2
             rounded-md my-2 hover:bg-slate-100 font-normal py-1 px-6" >
            <button className="flex flex-row" onClick={logout}>
                <IoIosLogOut className="size-3.5 text-[#8c8d91] mr-2"/>
                <span className="font-medium">Αποσύνδεση</span>
            </button>
        </li>
        </ul>
        
        </div>
  )
}

export default SettingsHeaderMenu