import Sales from '../../components/mainactivity/Sales'
import InvoiceNums from '../../components/mainactivity/InvoiceNums';
import Expenses from '../../components/mainactivity/Expenses';
function MainActivity() {
  
  return (
    <div className="flex flex-row  w-full overflow-y-hidden">
      <div className="grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 g-4 m-4 w-full">
      <Sales />
      <InvoiceNums />
      </div>
    </div>
     
  );
}

export default MainActivity;