export const HeaderMenu = [

        {
        title: "Εισαγωγή Παραστατικού",
        path: "/parastatika",
        cName: "nav-text",
        },
        {
        title: "Εισαγωγή Πελάτη",
        path:"/pelates",
        cName: "nav-text",
        },
        {
        title: "Εισαγωγή Είδους / Υπηρεσίας",
        path: "/eidh-yphresies",
        cName: "nav-text",
        },
        {
        title: "Είσπραξη Πελάτη",
        path: "/pelates",
        cName: "nav-text",
        },
        {
         title: "Ανανέωση Συνδρομής",
        path: "/eshop/ananewsh-sindromis",
        cName: "nav-text",
        },
];