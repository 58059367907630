import React from 'react'
import {useState, useEffect, useRef} from 'react'
import axios from 'axios'
import { PencilSquareIcon ,ArchiveBoxXMarkIcon } from '@heroicons/react/24/solid'
import { useNavigate, useLoaderData} from 'react-router-dom'
import Delete from '../Modals/rythmiseis_parastatikwn/Delete'

function RythmiseisParastatikwn() {
  const [typoi,setTypoi] = useState([]);
  const [error,setError] = useState();
  const [openError,setOpenError] = useState(false);
  const [data,setData] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteRow, setDeleteRow] = useState();
  const [rythmiseisParastatikwn,setRythmiseisParastatikwn] = useState([]);
  const updateRef = useRef();
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  let count=1;

  const navigate = useNavigate();

  const routeChange = () =>{ 
    navigate('/parametroi/rythmiseis-parastatikwn/nea_eggrafh');
  }

  const editTypos = (id) =>{
    navigate(`/parametroi/rythmiseis-parastatikwn/${id}`);
  }

  {/*Αρχικοποίηση data με όλους τους διαθέσιμους τύπους παραστατικών*/}
  const setTypoiParastatikwn = async ()=>{
    try {
      await axios.get(BASE_URL + '/parametroi/typoi-parastatikwn/',{withCredentials: true})
      .then(res=> setTypoi(res.data))
      .catch(err=>{
      throw new Error('Προέκυψε σφάλμα κατά τη σύνδεση με τον server')
      });
    } catch (error) {
      setError(error);
      setOpenError(true);
    }
}

const handleDeleteClick = ()=>{
  setOpenDeleteModal(!openDeleteModal);
  getRythmiseisParastatikwn();
}

const deleteThisRow = (item,openDeleteModal)=>{
  setDeleteRow(item);
  console.log(deleteRow);
  setOpenDeleteModal(!openDeleteModal);
}


 {/*Αρχικοποίηση πίνακα με τις ρυθμίσεις για κάποιυς τύπους παραστατικών ανά χρήστη*/}
  
const getRythmiseisParastatikwn = async ()=>{
  try{
    await axios.get(BASE_URL +'/parametroi/rythmiseis-parastatikwn',{withCredentials: true})
     .then(res=>setRythmiseisParastatikwn(res.data))
     .catch(err=>{
       throw new Error('Προέκυψε σφάλμα κατά τη σύνδεση με τον server');
     });
    } catch(error){
     setError(error);
     setOpenError(true);
    }
  }

useEffect(()=>{
    setTypoiParastatikwn();
    getRythmiseisParastatikwn();
},[rythmiseisParastatikwn])


  return (
    <div className="w-full overflow-x-auto lg:overflow-x-hidden flex flex-col justify-items-center p-10 text-center justify-center overflow-y-hidden bg-white mt-5 mx-4 rounded-md ">
      <h1 className="lg:text-2xl text-[#3a3b45] font-semibold">Ρυθμίσεις Παραστατικών</h1>
      <div className="p-8 justify-end text-right">
      <button className="justify-items-end text-left justify-right bg-[#1a97af] hover:bg-[#70b7c57a] text-white font-semibold py-2 px-4 border rounded" onClick={e=>routeChange()}>
        Νέος Τύπος Παραστατικού
      </button>

    <div className="flex justify-center justify-items-center mt-10">
    <table className="w-full shadow-md sm:rounded-lg mt-3 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <thead className="shadow-md rounded-md text-xs text-gray-700 bg-slate-300 uppercase dark:bg-gray-700 dark:text-gray-400">
        <tr className="bg-slate-200 w-full">
            <th className="pl-3 py-3">Α/Α</th>
            <th className="px-6 py-3">Τυπος Παραστατικου</th>
            <th className="px-6 py-3">Σειρα</th>
            <th className="px-6 py-3 w-8">Εναρξη Α/Α</th>
            <th className="px-6 py-3">Τρεχων Αριθμος</th>
            <th className="px-6 py-3">Περιγραφη</th>
        </tr>
        </thead>
        <tbody>
          {Object.values(rythmiseisParastatikwn).map((d,i)=>{
            
            return(
              <tr className="border-b" key={i} >
              <td className="px-6 py-4">{count++}</td>
              <td className="px-6 py-4">{d.typos_id}</td>
              <td className="px-6 py-4">{d.seira}</td>
              <td className="px-6 py-4">{d.enarksh_aa}</td>
              <td className="px-6 py-4">{d.trexwn_arithmos}</td>
              <td className="px-6 py-4">{d.perigrafh}</td>
              
              <td className="float-right p-2 flex flex-row">
                <button ref={updateRef} className=" flex h-10 mr-4 p-2 w-30 rounded border-1 border-slate-200 font-medium text-white  hover:bg-[#70b7c57a] bg-[#1a97af] " onClick={e=>editTypos(d.id)}>
                  <PencilSquareIcon className="size-6"/>
                  </button>

                  {d.isUsed === 0 ? (
                    <button className="flex h-10 p-2 w-30 rounded border-1 border-slate-200 font-medium text-white  hover:bg-red-300 bg-red-500" onClick={()=>deleteThisRow(d.id,openDeleteModal)}>
                      <ArchiveBoxXMarkIcon className="size-6"/>
                    </button>
                  ) : (
                    <button disabled={true} className="flex h-10 p-2 w-30 rounded border-1 border-slate-200 font-medium text-white  bg-red-300">
                      <ArchiveBoxXMarkIcon className="size-6"/>
                    </button>
                  )
                    
                  
                  }

                  

                
              </td>
            </tr>
            )
          })}
        </tbody>
      </table>
    </div>
    </div>
    <Delete open={openDeleteModal} onClose={handleDeleteClick} id={deleteRow}/>

    </div>
  )
}

export default RythmiseisParastatikwn


export async function loader(){
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const data = {
    typoi_parastatikwn: null,
    rythmiseisParastatikwn: null
  }
  
  try{
   data.typoi_parastatikwn = await axios.get(BASE_URL +'/parametroi/typoi-parastatikwn',{withCredentials: true})
    .then(
        data.rythmiseisParastatikwn = await axios.get(BASE_URL +'/parametroi/rythmiseis-parastatikwn',{withCredentials: true})
      .then(res=> res.data)
      .catch(err=>{
        throw new Error('Προέκυψε σφάλμα κατά τη σύνδεση με τον server')
      })
    )
    .catch(err=>{
      throw new Error('Προέκυψε σφάλμα κατά τη σύνδεση με τον server');
    });

    return data;
   } catch(error){
    throw new Error('Προέκυψε σφάλμα κατά τη σύνδεση με τον server');
   }

   return null;

}