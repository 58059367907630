import React from 'react'
import {useState} from 'react'
import {NavLink} from 'react-router-dom';
import { HeaderMenu } from './HeaderMenu';

function HeaderDropMenu({open}) {




  return (
    <div className={`${open? "border border-gray-200 absolute z-10 w-[90%] lg:w-60 md:w-40 shadow-sm float-end lg:ml-[1%] lg:mt-[3%] md:ml-[3%] md:mt-[8%] mt-[13%] mr-[8%]" : "max-h-0 invisible"} `}>
    <ul className={`${open? "pt-1 flex-col bg-white": "max-h-0 invisible"}  rounded-md transition-all duration-75 ease-in-out overflow-hidden`}> 
    {HeaderMenu.map((menu,index) => (
        <React.Fragment key = {index}>
        <li className="text-[#3a3b45] text-xs flex items-center gap-x-2 cursor-pointer p-2
         rounded-md my-2 hover:bg-slate-100 font-normal py-1 px-6"
         >
        <NavLink to={menu.path} className ="font-medium duration-200">
            {menu.title}
        </NavLink>

         </li>


        </React.Fragment>
    ))}
    
    </ul>
    </div>
  );
}

export default HeaderDropMenu