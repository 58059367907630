function loadScriptByURL(id, url, callback) {
    const script = document.createElement("script");
    script.id = id;
    script.src = url;
    script.async = true;
    script.defer = true;
    script.onload = callback;
    document.body.appendChild(script);
  }
  
  export async function getRecaptcha() {
    return new Promise((resolve) => {
      if (typeof window.grecaptcha === "undefined") {
        const SITE_KEY = process.env.REACT_APP_DOCON_RECAPTCHA_SITE_KEY;
        loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`, function () {
          if (typeof window.grecaptcha !== "undefined") {
            executeRecaptcha(resolve);
          } else {
            resolve(null);
          }
        });
      } else {
        executeRecaptcha(resolve);
      }
    });
  }
  
  function executeRecaptcha(resolve) {
    window.grecaptcha.ready(async () => {
      const SITE_KEY = process.env.REACT_APP_DOCON_RECAPTCHA_SITE_KEY;
  
      if (!SITE_KEY) {
        resolve(null);
        return;
      }
      const token = await window.grecaptcha.execute(SITE_KEY, {
        action: 'postForm'
      });
  
      resolve(token);
    });

    
  }